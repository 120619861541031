import React, { useEffect } from "react";
import Layout from "@components/layout";
import Header from "@components/header/header";
import Banner from "@components/banner";
import Video from "@components/video";
import AppFeature from "@components/app-feature";
import CallToAction from "@components/call-to-action";
import PriceHome from "@components/price/price-home";
import TestimonialsHome from "@components/testimonials/testimonials-home";
import AppShot from "@components/app-shot";
import BlogHome from "@components/blog/blog-home";
import ServicesHome from "@components/services/services-home";
import FAQ from "@components/faq";
import Brands from "@components/brands";
import Subscribe from "@components/subscribe";
import Footer from "@components/footer";
import MobileNav from "@components/mobile-nav";
import ApiClient from "@services/ApiClient";

const HomeOne = () => {

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    if (window.location.href.indexOf("=") > -1) {
      const code = queryParameters.get('code');
      if (code) {
       ApiClient.addUserToGuild(code).then(result => {
        window.location.href = result["result"];
       })
      }
    }
  }, [])

  return (
    <Layout pageTitle="Home Page || WICEBOT">
      <Header />
      <Banner />
      <ServicesHome />
      {/*<CallToAction />
      <Video />
      <AppFeature />*/}
      <PriceHome />
      {/*<TestimonialsHome />
      <AppShot />*/}
      <FAQ />
      <BlogHome />
      {/*<Brands />*/}
      {/*<Subscribe />*/}
      <Footer />
      <MobileNav />
    </Layout>
  );
};

export default HomeOne;
