
export default class ApiClient {
    static reqDict = {};
    static apiUrl = "https://api.wicebot.guru";
    //static apiUrl = "http://127.0.0.1:8080";
    static addUserToGuild = async (access_token: string): Promise<string> => {
        try {
            const data = fetch(ApiClient.apiUrl + "/adduser", {
                method: "POST",
                body: JSON.stringify({
                    token: access_token,
                    plan: ""
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }).then(res => {
                return res.json();
            })
            let result = await data;
            return result;
        } catch (error) {
            console.log(error);
        }
    }
}